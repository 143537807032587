<template>
  <div class="pa-10 pt-5">
    <v-row dense align="center" justify="center" style="margin-bottom: -20px">
      <v-col cols="12">
        <div class="d-flex justify-space-between">
          <h2>List of Submitted Recording</h2>
          <img
            @click="$emit('closeDialog', !submittedRecordDialog)"
            :style="{ width: '30px', height: '30px', cursor: 'pointer ' }"
            src="../../../assets/images/icon-close2.svg"
            alt=""
          />
        </div>
      </v-col>
      <v-col cols="12">
        <p>Total: {{ filtered.length }} records</p>
      </v-col>
    </v-row>
    <template v-if="isLoading">
      <div class="pa-6">
        <v-progress-linear indeterminate color="primary"></v-progress-linear>
      </div>
    </template>
    <v-row :style="{ maxHeight: '50vh' }">
      <v-col cols="12">
        <v-data-table
          v-if="!isLoading"
          :headers="headers"
          :items="filtered"
          :items-per-page="itemsPerPage"
          :page.sync="page"
          @page-count="pageCount = $event"
          hide-default-footer
          class="elevation-0 mb-10"
          fixed-header
          height="40vh"
        >
          <template v-slot:header="{ props }">
            <tr>
              <td v-for="header in props.headers" :key="header.value">
                <div :class="'d-flex justify-center'" v-if="filters[header.value]">
                  <Autocomplete
                    v-if="filters.hasOwnProperty(header.value)"
                    @setFilter="setFilter"
                    :styleBorder="'border-bottom'"
                    :keyFilter="header.value"
                    :selectedInit="filters[header.value]"
                    :listItem="
                      header.value === 'recording'
                        ? ['Has comment', 'No comment']
                        : groupColumnValueList(header.value)
                    "
                  />
                </div>
                <div v-else>
                  <td></td>
                </div>
              </td>
            </tr>
          </template>
          <template v-slot:[`item.no`]="{ index }">
            <p>
              {{ index + 20 * (page - 1) + 1 }}
            </p>
          </template>
          <template v-slot:[`item.studentCode`]="{ item }">
            <p class="link" @click="viewDetailUser(item.student_userID)">{{ item.studentCode }}</p>
          </template>
          <template v-slot:[`item.studentName`]="{ item }">
            <p
              class="link"
              :style="{ width: '160px' }"
              @click="viewDetailUser(item.student_userID)"
            >
              {{ item.studentName }}
            </p>
          </template>
          <template v-slot:[`item.recordContent`]="{ item }">
            <p :style="{ width: '400px' }">{{ item.recordContent }}</p>
          </template>
          <template v-slot:[`item.classScheduleDate`]="{ item }">
            <p class="text-center">{{ item.classScheduleDate | getRecordDeadline | getDate }}</p>
          </template>
          <template v-slot:[`item.studentSubmitDate`]="{ item }">
            <p class="text-center">
              {{ item.studentSubmitDate | getDate }}
            </p>
          </template>
          <template v-slot:[`item.recording`]="{ item }">
            <div :class="'w-150'">
              <div :class="'d-flex align-center w-150'">
                <img
                  @click="openRecordMark(item)"
                  :src="item.isCommentOrMark === true ? iconDoneAndMark : iconDone"
                  alt=""
                  srcset=""
                />
                <div class="d-flex align-center link" @click="viewVideo(item)" target="_blank">
                  <img
                    class="ml-2 link"
                    src="../../../assets/images/icon-play.svg"
                    alt=""
                    srcset=""
                  />
                  <p :class="'link ml-1 mr-0'">View</p>
                </div>
              </div>
            </div>
          </template>
        </v-data-table>
        <div :style="{ width: '97%', bottom: '10px', position: 'absolute' }">
          <div class="d-flex align-center justify-space-between">
            <v-select
              outlined
              hide-details
              dense
              :value="itemsPerPage"
              :items="[20, 50, 100, 200]"
              @change="itemsPerPage = parseInt($event, 10)"
              style="height: 32px; max-width: 15%"
            ></v-select>
            <v-pagination
              v-model="page"
              class="mr-8"
              :length="Math.ceil(filtered.length.toFixed() / itemsPerPage)"
              :total-visible="7"
              elevation="0"
            ></v-pagination>
          </div>
        </div>
      </v-col>
    </v-row>
    <div class="text-center">
      <v-dialog v-if="dialogRecordMark" v-model="dialogRecordMark" width="900">
        <v-card style="aspect-ratio: 4/3">
          <v-form
            @submit.prevent="markRecord"
            ref="formRecord"
            :class="'record-form'"
            v-model="valid"
          >
            <v-card-title class="text-h5">
              <div :class="' fz-1 header-comment d-flex justify-space-between align-center'">
                <h4>Comment on recording</h4>
                <div>
                  <v-btn
                    :class="'btn-comment-record mr-3'"
                    @click="closeRecordMark"
                    outlined
                    height="30px"
                    >Close</v-btn
                  >
                  <v-btn
                    :class="'btn-comment-record'"
                    filled
                    height="30px"
                    color="primary"
                    type="submit"
                    :disabled="!valid || !isValidEditor1"
                    >Send feedback</v-btn
                  >
                </div>
              </div>
              <div
                :style="{ width: '100%' }"
                class="d-flex align-center justify-space-between mt-4"
              >
                <div class="d-flex align-center">
                  <h4 class="mr-6 fz-1">
                    {{ recordSelect.recordCategory }} - {{ recordSelect.studentName }}
                  </h4>
                  <span class="mt-1 fz-1">Total score: {{ totalMarkRecord }}</span>
                </div>
                <p class="fz-1">
                  Send by {{ recordSelect.sentByUser }} -
                  {{ formatTime(recordSelect.sentByDate) }}
                </p>
              </div>
            </v-card-title>

            <v-divider></v-divider>
            <v-card-text>
              <v-row>
                <v-col cols="12" :class="'pt-1 pb-1'">
                  <div class="editor">
                    <span>General comment</span>
                    <vue-editor
                      v-model="generalComment"
                      :editor-toolbar="customToolbar"
                      id="editor1"
                      placeholder="Add your comments..."
                    >
                    </vue-editor>
                    <p v-if="!isValidEditor1" class="error--text">This field is required</p>
                  </div>
                </v-col>

                <v-col cols="10" :class="'pt-1 pb-1'">
                  <div class="editor">
                    <span>Pronunciation</span>
                    <vue-editor
                      :editor-toolbar="customToolbar"
                      v-model="pronunciation"
                      id="editor2"
                      placeholder="Add your comments..."
                    >
                    </vue-editor>
                    <!-- <p v-if="!isValidEditor2" class="error--text">This field is required</p> -->
                  </div>
                </v-col>
                <v-col cols="2" :class="'pt-1 pb-1 mt-5'">
                  <v-text-field
                    v-model="scorePronunciation"
                    @wheel="preventScroll"
                    outlined
                    max="10"
                    min="0"
                    type="number"
                    height="80px"
                    :rules="numberRules"
                    label="Score"
                  ></v-text-field>
                </v-col>
                <v-col cols="10" :class="'pt-1 pb-1'">
                  <div class="editor">
                    <span>Fluency in speaking</span>
                    <vue-editor
                      :editor-toolbar="customToolbar"
                      id="editor3"
                      v-model="fluencyInSpeaking"
                      placeholder="Add your comments..."
                    >
                    </vue-editor>
                    <!-- <p v-if="!isValidEditor3" class="error--text">This field is required</p> -->
                  </div>
                </v-col>
                <v-col cols="2" :class="'pt-1 pb-1'">
                  <v-text-field
                    v-model="scoreFluencyInSpeaking"
                    @wheel="preventScroll"
                    outlined
                    max="10"
                    min="0"
                    type="number"
                    class="pb-1 pt-1 mt-4"
                    :rules="numberRules"
                    label="Score"
                    height="80px"
                  ></v-text-field>
                </v-col>
                <v-col cols="10" :class="'pt-1 pb-1'">
                  <div class="editor">
                    <span>Vocabulary and grammar</span>
                    <vue-editor
                      :editor-toolbar="customToolbar"
                      v-model="vocabularyAndGrammar"
                      id="editor4"
                      placeholder="Add your comments..."
                    >
                    </vue-editor>
                    <!-- <p v-if="!isValidEditor4" class="error--text">This field is required</p> -->
                  </div>
                </v-col>
                <v-col cols="2" :class="'pt-1 pb-1 mt-5'">
                  <v-text-field
                    v-model="scoreVocabularyAndGrammar"
                    @wheel="preventScroll"
                    outlined
                    :rules="numberRules"
                    label="Score"
                    height="80px"
                    max="10"
                    min="0"
                    type="number"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-form>
        </v-card>
      </v-dialog>
      <div v-if="dialogVideo" :class="'dig-video'">
        <v-dialog content-class="elevation-0" width="700px" v-model="dialogVideo">
          <div :style="{ width: 'fit-content', marginLeft: 'auto' }">
            <img
              @click="dialogVideo = false"
              :style="{ width: '30px', height: '30px', cursor: 'pointer ' }"
              src="../../../assets/images/icon-close2.svg"
              alt=""
            />
          </div>
          <v-card
            class="pt-5 pb-4"
            width="800"
            :height="recordSelect.urLs.length > 0 ? 600 : 450"
            :style="
              recordSelect.urLs.length > 0
                ? { overflowY: 'scroll', overflowX: 'hidden' }
                : { overflowX: 'hidden', overflowY: 'hidden' }
            "
          >
            <v-row class="px-8 pb-8">
              <span
                v-if="recordSelect.rootLinkTitle"
                :style="{ fontSize: '20px', fontWeight: '700' }"
                >{{ recordSelect.rootLinkTitle }}</span
              >
              <iframe
                class="mt-4"
                v-if="recordSelect.url"
                :style="{ width: '101%', height: '400px', margin: '0 auto' }"
                :src="urlChange(recordSelect.url)"
                allowfullscreen
              ></iframe>
            </v-row>
            <div v-if="recordSelect.urLs">
              <div v-if="recordSelect.urLs.length > 0">
                <div v-for="(item, index) in recordSelect.urLs" :key="index" class="d-flex px-8">
                  <v-row class="pb-8">
                    <span
                      v-if="item.urlTitle"
                      class="mt-2"
                      :style="{ fontSize: '20px', fontWeight: '700' }"
                      >{{ item.urlTitle }}</span
                    >
                    <iframe
                      v-if="item.urlLink"
                      :style="{ width: '101%', height: '400px', margin: '0 auto' }"
                      :src="urlChange(item.urlLink)"
                      allowfullscreen
                    ></iframe>
                  </v-row>
                </div>
              </div>
            </div>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'

import { class_managerment } from '@/api/class-management.js'
import Autocomplete from '@/components/Utils/Autocomplete.vue'
import iconDoneAndMark from '../../../assets/images/exam-result-public.svg'
import iconDone from '../../../assets/images/icon-comment.svg'
import { VueEditor } from 'vue2-editor'
/**
 * ! import mixins for filter
 */
import ViewDetail from '@/mixins/viewDetail.js'
import ConfigDate from '@/mixins/configDate.js'

export default {
  components: { Autocomplete, VueEditor },
  mixins: [ViewDetail, ConfigDate],
  setup() {
    return {}
  },
  props: {
    submittedRecordDialog: {
      type: Boolean,
    },
    listItem: {
      type: Array,
    },
  },
  data() {
    return {
      isLoading: false,
      dayjs: dayjs,
      numberRules: [v => !!v || 'This filed is required', v => !isNaN(v) || 'Must be number'],
      selectedTemplate: null,
      valid: false,
      dialogSelectTemplate: false,
      dialogRecordMark: false,
      iconDone: iconDone,
      iconDoneAndMark: iconDoneAndMark,
      fluencyInSpeaking: '',
      vocabularyAndGrammar: '',
      scoreGeneralComment: 0,
      scorePronunciation: 0,
      scoreFluencyInSpeaking: 0,
      scoreVocabularyAndGrammar: 0,
      totalMarkRecord: 0,
      dialogVideo: false,
      generalComment: '',
      pronunciation: '',
      recordSelect: {},
      hoverID: '',
      page: 1,
      itemsPerPage: 100,
      pageCount: 0,
      headers: [
        {
          text: 'No',
          value: 'no',
          sortable: false,
        },
        {
          text: 'Student Code',
          value: 'studentCode',
          sortable: false,
        },
        {
          text: 'Student Name',
          value: 'studentName',
          sortable: false,
        },
        {
          text: "Recording's Name",
          value: 'recordCategory',
          sortable: false,
        },
        {
          text: 'Recording Content',
          value: 'recordContent',
          sortable: false,
        },
        {
          text: 'Deadline',
          value: 'classScheduleDate',
          sortable: false,
        },
        {
          text: 'Submitted Date',
          value: 'studentSubmitDate',
          sortable: false,
        },
        {
          text: 'Recording',
          value: 'recording',
          sortable: false,
        },
      ],
      filters: {
        studentCode: [],
        studentName: [],
        recordCategory: [],
        recordDate: [],
        studentSubmitDate: [],
        recording: [],
      },
    }
  },
  created() {
    this.init()
  },
  mounted() {},
  watch: {},
  computed: {
    filtered() {
      return this.listItem?.filter(d => {
        return Object.keys(this.filters).every(f => {
          return (
            this.filters[f].length < 1 ||
            (f === 'recording'
              ? this.filters[f].reduce((value, el) => {
                  if (d.isCommentOrMark && el === 'Has comment') {
                    value = true
                  }
                  if (!d.isCommentOrMark && el === 'No comment') {
                    value = true
                  }
                  return value
                }, false)
              : this.filters[f].includes(d[f]))
          )
        })
      })
    },
    isValidEditor1() {
      return this.generalComment.length > 0
    },
  },
  methods: {
    setFilter(objectFilterChange) {
      this.filters = {
        ...this.filters,
        [objectFilterChange.name]: objectFilterChange.filter,
      }
      localStorage.setItem('usingTemplateFilter', JSON.stringify(this.filters))
    },
    preventScroll(event) {
      // Prevent the default scroll behavior
      event.preventDefault()
    },
    groupColumnValueList(val) {
      return this.listItem.map(d => d[val]).filter(y => y)
    },
    formatTime(date) {
      try {
        if (new Date(date) !== 'Invalid Date' && date !== null) {
          const fbTimeArr = date.split('T')
          return (
            fbTimeArr[1].split(':')[0] +
            ':' +
            fbTimeArr[1].split(':')[1] +
            ' ' +
            dayjs(date).format('DD/MM/YYYY')
          )
        } else {
          return ''
        }
      } catch (err) {
        return ''
      }
    },
    urlChange(url) {
      let url2 = url
      if (url2.indexOf('/drive.google.com/file') !== -1) {
        const listLinkArr = url2.split('/')
        listLinkArr.pop()
        url2 = listLinkArr.join('/') + '/preview'
      }
      if (url2.indexOf('youtu') !== -1) {
        if (url2.indexOf('embed') === -1) {
          url2 = 'https://www.youtube.com' + '/embed/' + this.youtube_parser(url2)
        }
      }
      return url2
    },
    youtube_parser(url) {
      var r,
        rx =
          /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|&v(?:i)?=))([^#&?]*).*/

      r = url.match(rx)
      return r[1]
    },
    viewVideo(record) {
      ;(this.dialogVideo = true), (this.recordSelect = record)
    },
    openRecordMark(data) {
      this.resetMark()
      if (data.isCommentOrMark) {
        this.generalComment = data.generalComment
        this.pronunciation = data.pronunciation
        this.fluencyInSpeaking = data.fluencyInSpeaking
        this.vocabularyAndGrammar = data.vocabularyAndGrammar
        this.scorePronunciation = parseFloat(data.scorePronunciation)
        this.scoreFluencyInSpeaking = parseFloat(data.scoreFluencyInSpeaking)
        this.scoreVocabularyAndGrammar = parseFloat(data.scoreVocabularyAndGrammar)
        this.totalMarkRecord =
          parseFloat(data.scoreGeneralComment) +
          parseFloat(data.scorePronunciation) +
          parseFloat(data.scoreFluencyInSpeaking) +
          parseFloat(data.scoreVocabularyAndGrammar)
      }

      this.dialogRecordMark = true
      this.recordSelect = data
    },
    resetMark() {
      this.generalComment = ''
      this.totalMarkRecord = 0
      this.pronunciation = ''
      this.fluencyInSpeaking = ''
      this.vocabularyAndGrammar = ''
      this.scoreGeneralComment = ''
      this.scorePronunciation = 0
      this.scoreFluencyInSpeaking = 0
      this.scoreVocabularyAndGrammar = 0
    },
    closeRecordMark() {
      this.resetMark()
      this.dialogRecordMark = false
      this.$refs.formRecord.resetValidation()
    },
    async markRecord() {
      let comment = {
        recordID: this.recordSelect.recordID,
        generalComment: this.generalComment,
        pronunciation: this.pronunciation,
        fluencyInSpeaking: this.fluencyInSpeaking,
        scoreGeneralComment: 0,
        vocabularyAndGrammar: this.vocabularyAndGrammar,
        scorePronunciation: parseFloat(this.scorePronunciation),
        scoreFluencyInSpeaking: parseFloat(this.scoreFluencyInSpeaking),
        scoreVocabularyAndGrammar: parseFloat(this.scoreVocabularyAndGrammar),
      }
      await class_managerment.updateComment(comment).then(() => {
        this.$emit('callData')
        this.dialogRecordMark = false
        this.$emit('closeDialog', !this.submittedRecordDialog)
      })
    },
    async init() {
      this.listItem.forEach(item => {
        if (
          (item.fluencyInSpeaking === '' &&
            item.pronunciation === '' &&
            item.generalComment === '' &&
            item.vocabularyAndGrammar === '' &&
            item.scoreFluencyInSpeaking === 0 &&
            item.scoreGeneralComment === 0 &&
            item.scorePronunciation === 0 &&
            item.scorePronunciation === 0) ||
          (item.fluencyInSpeaking === null &&
            item.pronunciation === null &&
            item.generalComment === null &&
            item.vocabularyAndGrammar === null &&
            item.scoreFluencyInSpeaking === null &&
            item.scoreGeneralComment === null &&
            item.scorePronunciation === null &&
            item.scorePronunciation === null)
        ) {
          item.isCommentOrMark = false
        } else {
          item.isCommentOrMark = true
        }
        if (!item.urLs) item.urLs = []
      })
    },
    _dayjs: dayjs,
  },
}
</script>

<style lang="scss" scoped>
.link {
  color: #0288d1;
  &:hover {
    cursor: pointer;
  }
}
.btn-comment-record {
  width: 120px;
  font-size: 10px;
}
.record-form {
  width: 100%;
}
.header-comment {
  width: 100%;
}

.h {
  background: rgba(0, 0, 0, 0.01);
  position: fixed;
  width: 100%;
  height: 100%;

  top: 0;
  z-index: 1000;
  left: 0;
}

::-webkit-scrollbar {
  width: 16px;
}

::-webkit-scrollbar-track {
  background: #ffffff;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(181, 181, 181);
  border-radius: 10px;
  border: 3px solid #ffffff;
}
.fz-1 {
  font-size: 16px;
}
</style>
