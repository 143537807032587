<template>
  <div
    v-if="currentRole === 4 || currentRole === 5"
    id="recording-management"
    class="d-flex flex-column"
  >
    <v-row no-gutters>
      <v-col md="12">
        <div>
          <div class="d-flex mt-5" style="z-index: 100">
            <Autocomplete
              @setFilter="setFilter"
              :styleBorder="'out-lined'"
              placeholder="Select..."
              :keyFilter="headers[0].value"
              :selectedInit="filters[headers[0].value]"
              :listItem="headers[0].items"
              :announLabel="headers[0].announLabel"
            />
            <Autocomplete
              @setFilter="setFilter"
              :styleBorder="'out-lined'"
              placeholder="Select..."
              :keyFilter="headers[1].value"
              :selectedInit="filters[headers[1].value]"
              :listItem="headers[1].items"
              :announLabel="headers[1].announLabel"
            />
            <Autocomplete
              @setFilter="setFilter2"
              :styleBorder="'out-lined'"
              placeholder="Select..."
              :keyFilter="headers[2].value"
              :selectedInit="filters[headers[2].value]"
              :listItem="headers[2].items"
              :announLabel="headers[2].announLabel"
            />
            <v-text-field
              style="max-width: 150px"
              type="date"
              v-model="fromDate"
              label="From date"
              class="select-input mr-3"
              outlined
              dense
              hide-details
              @change="changeFromDate"
            />
            <v-text-field
              style="max-width: 150px"
              type="date"
              v-model="toDate"
              label="To date"
              class="select-input"
              outlined
              dense
              hide-details
              @change="changeToDate"
            />
            <v-btn class="ml-3" color="primary" @click="searchRecordByDate">Search</v-btn>
            <v-btn class="ml-auto" color="primary" @click="downloadExcel"
              >Export Class Recording Table</v-btn
            >
          </div>
          <v-progress-linear
            v-if="isLoading"
            indeterminate
            color="primary"
            class="my-10"
          ></v-progress-linear>
          <v-data-table
            v-if="!isLoading"
            :headers="recordHeader"
            :items="filtered"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            @page-count="pageCount = $event"
            hide-default-footer
            hide-default-header
            class="elevation-0 record-table"
            fixed-header
            height="60vh"
          >
            <template v-slot:header="{ props }">
              <thead :class="'record-header'">
                <tr>
                  <th
                    :style="{ color: '#ffff !important' }"
                    v-for="(header, index) in props.headers"
                    :key="index"
                  >
                    <p :style="{ textAlign: 'center !important' }">
                      {{ header.text }}
                    </p>
                  </th>
                </tr>
              </thead>
              <tr class="fixed-row-filter">
                <td v-for="header in props.headers" :key="header.text">
                  <div v-if="recordFilters.hasOwnProperty(header.value)">
                    <AutoFilter
                      v-if="recordFilters.hasOwnProperty(header.value)"
                      @setFilter="setFilter3"
                      :styleBorder="'border-bottom'"
                      :keyFilter="header.value"
                      :selectedInit="recordFilters[header.value]"
                      :listItem="groupColumnValueList(header.value)"
                    />
                  </div>
                </td>
              </tr>
            </template>
            <template v-slot:body="{ items }">
              <tbody v-if="classList.length === 0" style="text-align: center">
                <div
                  class="pt-3"
                  style="position: absolute; left: 50%; transform: translate(-50%, 0)"
                >
                  No data available
                </div>
              </tbody>
              <tbody v-else :class="'record-body'">
                <tr v-for="(item, index) in items" :key="item.student_userID">
                  <td :style="{ width: '20px' }">
                    <p :style="{ width: '20px', textAlign: 'center' }">
                      {{ itemsPerPage * (page - 1) + index + 1 }}
                    </p>
                  </td>

                  <td :class="'link'">
                    <p :style="{ textAlign: 'center' }" @click="viewDetailClass(item.classID)">
                      {{ item.classCode }}
                    </p>
                  </td>
                  <td>
                    <p :style="{ textAlign: 'center' }">
                      {{ item.studentNbr }}
                    </p>
                  </td>
                  <td :class="'link'">
                    <p :style="{ textAlign: 'center' }" @click="viewDetailProfile(item.qc_userID)">
                      {{ item.qcName }}
                    </p>
                  </td>
                  <td :class="'link'">
                    <p :style="{ textAlign: 'center' }" @click="viewDetailProfile(item.ec_userID)">
                      {{ item.ecName }}
                    </p>
                  </td>
                  <td class="d-flex justify-center align-center">
                    <p class="pr-10">
                      {{ item.submittedRecord.length }}
                    </p>
                    <div class="d-flex align-center">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            size="18"
                            class="mr-2"
                            @click="
                              submittedRecordDialog = true
                              listItem = item.submittedRecord
                            "
                            color="primary"
                            v-bind="attrs"
                            v-on="on"
                            >visibility
                          </v-icon>
                        </template>
                        <span>View</span>
                      </v-tooltip>
                    </div>
                  </td>
                  <td>
                    <p :style="{ textAlign: 'center' }">
                      {{ item.graded }}
                    </p>
                  </td>
                  <td>
                    <p :style="{ textAlign: 'center' }">
                      {{ item.ungraded }}
                    </p>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
          <v-data-table
            :headers="recordHeader"
            :items="filtered"
            :items-per-page="1000000"
            hide-default-footer
            hide-default-header
            class="elevation-0 record-table"
            fixed-header
            height="0vh"
            id="exported-class-recording"
          >
            <template v-slot:header="{ props }">
              <thead :class="'record-header'">
                <tr>
                  <th
                    :style="{ color: '#ffff !important' }"
                    v-for="(header, index) in props.headers"
                    :key="index"
                  >
                    <p :style="{ textAlign: 'center !important' }">
                      {{ header.text }}
                    </p>
                  </th>
                </tr>
              </thead>
            </template>
            <template v-slot:body="{ items }">
              <tbody v-if="classList.length === 0" style="text-align: center">
                <div
                  class="pt-3"
                  style="position: absolute; left: 50%; transform: translate(-50%, 0)"
                >
                  No data available
                </div>
              </tbody>
              <tbody v-else :class="'record-body'">
                <tr v-for="(item, index) in items" :key="item.student_userID">
                  <td :style="{ width: '20px' }">
                    <p :style="{ width: '20px', textAlign: 'center' }">
                      {{ index + 1 }}
                    </p>
                  </td>

                  <td :class="'link'">
                    <p :style="{ textAlign: 'center' }" @click="viewDetailClass(item.classID)">
                      {{ item.classCode }}
                    </p>
                  </td>
                  <td>
                    <p :style="{ textAlign: 'center' }">
                      {{ item.studentNbr }}
                    </p>
                  </td>
                  <td :class="'link'">
                    <p :style="{ textAlign: 'center' }" @click="viewDetailProfile(item.qc_userID)">
                      {{ item.qcName }}
                    </p>
                  </td>
                  <td :class="'link'">
                    <p :style="{ textAlign: 'center' }" @click="viewDetailProfile(item.ec_userID)">
                      {{ item.ecName }}
                    </p>
                  </td>
                  <td class="d-flex justify-center align-center">
                    <p class="pr-10">
                      {{ item.submittedRecord.length }}
                    </p>
                  </td>
                  <td>
                    <p :style="{ textAlign: 'center' }">
                      {{ item.graded }}
                    </p>
                  </td>
                  <td>
                    <p :style="{ textAlign: 'center' }">
                      {{ item.ungraded }}
                    </p>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </div>
      </v-col>
    </v-row>
    <v-dialog v-if="submittedRecordDialog" v-model="submittedRecordDialog" width="1400" persistent>
      <v-card :style="{ borderRadius: `25px`, overflowY: 'hidden' }"
        ><SubmittedRecord
          :submittedRecordDialog="submittedRecordDialog"
          :listItem="listItem"
          @callData="searchRecordByDate"
          @closeDialog="handleClosePopup"
      /></v-card>
    </v-dialog>
    <v-footer v-if="!isLoading" height="60" color="#fff" width="82%">
      <v-row>
        <v-col md="3">
          <v-select
            outlined
            hide-details
            dense
            :value="itemsPerPage"
            :items="[20, 50, 100, 200]"
            @change="itemsPerPage = parseInt($event, 10)"
            style="height: 32px"
          ></v-select>
        </v-col>
        <v-col class="d-flex justify-end">
          <v-pagination v-model="page" :length="pageCount" elevation="0"></v-pagination>
        </v-col>
      </v-row>
    </v-footer>
  </div>
</template>

<script>
import { class_managerment } from '@/api/class-management.js'
import dayjs from 'dayjs'
import Autocomplete from '@/components/Utils/AutocompleteAnnoncement.vue'
import AutoFilter from '@/components/Utils/Autocomplete.vue'
import SubmittedRecord from './SubmittedRecord.vue'
import XLSX from 'xlsx-js-style'
// import iconDoneAndMark from '../../../assets/images/icon-doneAndMark.svg'
// import iconDone from '../../../assets/images/icon-comment.svg'
// import DatePicker from 'vue2-datepicker'
import { getObjectPermission } from '../../../common/index'
export default {
  components: { Autocomplete, AutoFilter, SubmittedRecord },
  data() {
    return {
      currentRole: JSON.parse(localStorage.getItem('currentUser')).userInfo.accountTypeID,
      classStatusIDs: [3],
      listItem: [],
      valid: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 100,
      submittedRecordDialog: false,
      numberRules: [v => !!v || 'This filed is required', v => !isNaN(v) || 'Must be number'],
      textRules: [v => !!v || 'This filed is required'],
      permision: getObjectPermission(416),
      categoryList: [],
      centers: JSON.parse(localStorage.getItem('currentUser')).locationPermissions,
      statusList: [],
      classList: [],
      classIDs: [],
      headers: [],
      filters: {
        location: [],
        classCode: [],
        classStattus: [],
      },
      recordFilters: {
        classCode: [],
        ecName: [],
        qcName: [],
        graded: [],
        ungraded: [],
      },
      recordHeader: [
        {
          text: 'No',
          value: 'no',
          sortable: false,
          width: '50px',
        },
        {
          text: 'Class code',
          value: 'classCode',
          sortable: false,
          width: '50px',
        },
        {
          text: 'Number of students',
          value: 'studentNbr',
          sortable: false,
          width: '50px',
        },
        {
          text: 'QC',
          value: 'qcName',
          sortable: false,
          width: '50px',
        },
        {
          text: 'EC',
          value: 'ecName',
          sortable: false,
          width: '50px',
        },
        {
          text: 'Number of Received Recording',
          value: 'submittedRecord',
          sortable: false,
          width: '50px',
        },
        {
          text: 'Graded',
          value: 'graded',
          sortable: false,
          width: '50px',
        },
        {
          text: 'Ungraded',
          value: 'ungraded',
          sortable: false,
          width: '50px',
        },
      ],
      recordData: [],
      fromDate: new Date().toISOString().split('T')[0],
      toDate: new Date().toISOString().split('T')[0],
      selectedSection: 'recordSectionByDate',
      isLoading: false,
    }
  },
  computed: {
    filtered() {
      return this.recordData.filter(d => {
        return Object.keys(this.recordFilters).every(f => {
          return (
            this.recordFilters[f.toString()].length < 1 ||
            this.recordFilters[f.toString()].includes(d[f.toString()])
          )
        })
      })
    },
  },
  created() {
    this.getCenter()
    this.getClassStatus()
    this.getClassInit()
  },

  methods: {
    logHeader() {
      this.headers = [
        {
          announLabel: 'Center',
          value: 'location',
          items: this.centers.map(el => el.location),
        },
        {
          announLabel: 'Class status',
          value: 'classStattus',
          items: this.statusList.map(el => el.classStatus),
        },
        {
          announLabel: 'Class',
          value: 'classCode',
          items: this.classList.map(el => el.classCode),
        },
      ]
    },
    getCenter() {
      this.filters.location = this.getIdArray(
        this.centers,
        [parseInt(localStorage.getItem('currentCenter'))],
        'locationID',
        'location'
      )
    },
    changeFromDate(val) {
      this.fromDate = val
    },
    changeToDate(val) {
      this.toDate = val
    },
    getIdArray(arr1, arr2, name, id) {
      return arr1.filter(item => arr2.includes(item[name])).map(item => item[id])
    },
    setFilter(objectFilterChange) {
      this.filters = {
        ...this.filters,
        [objectFilterChange.name]: objectFilterChange.filter,
      }
      const locationIDs = this.getIdArray(
        this.centers,
        this.filters.location,
        'location',
        'locationID'
      )
      const classStatuses = this.getIdArray(
        this.statusList,
        this.filters.classStattus,
        'classStatus',
        'classStatusID'
      )
      if (this.filters.location.length > 0 && this.filters.classStattus.length > 0)
        this.getClass(locationIDs, classStatuses)
    },
    setFilter2(objectFilterChange) {
      this.filters = {
        ...this.filters,
        [objectFilterChange.name]: objectFilterChange.filter,
      }
      this.classIDs = this.getIdArray(
        this.classList,
        this.filters.classCode,
        'classCode',
        'classID'
      )
    },
    setFilter3(objectFilterChange) {
      this.recordFilters = {
        ...this.recordFilters,
        [objectFilterChange.name]: objectFilterChange.filter,
      }
    },
    groupColumnValueList(val) {
      if (val === 'graded') {
        return [...new Set(this.recordData.map(d => d[val]))].sort((a, b) => a - b)
      }
      if (val === 'ungraded') {
        return [...new Set(this.recordData.map(d => d[val]))].sort((a, b) => a - b)
      }
      return this.recordData.map(d => d[val]).filter(y => y)
    },
    viewVideo(record) {
      ;(this.dialogVideo = true), (this.recordSelect = record)
    },
    viewDetailProfile(userId) {
      this.$router.push({
        name: 'account-management-view-account',
        params: {
          userId: userId,
        },
      })
    },
    viewDetailClass(classID) {
      this.$router.push({
        name: 'class-management-detail',
        query: { classID: classID },
      })
    },
    handleClosePopup(newValue) {
      this.submittedRecordDialog = newValue
    },
    searchRecordByDate() {
      if (this.filters.classCode.length > 0 && this.fromDate && this.toDate)
        this.getRecordByDate(this.classIDs, this.fromDate, this.toDate)
    },
    downloadExcel() {
      if (this.filtered.length > 0) {
        var table_elt = document.getElementById('exported-class-recording')
        var workbook = XLSX.utils.table_to_book(table_elt)
        const firstSheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[firstSheetName]

        const columnWidths = [
          { wch: 5 },
          { wch: 15 },
          { wch: 10 },
          { wch: 25 },
          { wch: 25 },
          { wch: 10 },
          { wch: 5 },
          { wch: 5 }
        ]
        worksheet['!cols'] = columnWidths

        const title =
          'Student Recording from ' +
          dayjs(this.fromDate).format('DD-MM-YYYY') +
          ' to ' +
          dayjs(this.toDate).format('DD-MM-YYYY') 
        XLSX.writeFile(workbook, title + '.xlsm')
      } 
    },
    async getClassStatus() {
      await class_managerment.getClassStatus().then(async res => {
        if (res.length > 0) {
          this.statusList = res
          this.filters.classStattus = this.getIdArray(res, [3], 'classStatusID', 'classStatus')
          const locationIDs = this.getIdArray(
            this.centers,
            this.filters.location,
            'location',
            'locationID'
          )
          await class_managerment.getClassesByLocations(locationIDs, [3]).then(res => {
            if (res) {
              this.classList = res
              this.filters.classCode = res.map(el => el.classCode)
              this.classIDs = this.getIdArray(res, this.filters.classCode, 'classCode', 'classID')
              this.logHeader()
            }
          })
        }
      })
    },
    async getClass(location, status) {
      await class_managerment.getClassesByLocations(location, status).then(res => {
        if (res) {
          this.classList = res
          this.logHeader()
        }
      })
    },
    async getRecordByDate(classIDs, fromDate, toDate) {
      this.isLoading = true
      try {
        const res = await class_managerment.getStudentRecordByClass(classIDs, fromDate, toDate)

        if (res) {
          const studentNbrPromises = res.map(async el => {
            const classOverview = await class_managerment.getClassOverview(el.classID)
            return {
              ...el,
              studentNbr: classOverview.nbrStu,
              graded: el.submittedRecord.reduce(
                (acc, element) => (this.hasGrade(element) ? acc + 1 : acc),
                0
              ),
              ungraded: el.submittedRecord.reduce(
                (acc, element) => (!this.hasGrade(element) ? acc + 1 : acc),
                0
              ),
            }
          })

          this.recordData = await Promise.all(studentNbrPromises)
          this.isLoading = false
        }
      } catch (error) {
        console.error('An error occurred:', error)
        this.isLoading = false
      }
    },

    hasGrade(item) {
      return (item.fluencyInSpeaking === '' &&
        item.pronunciation === '' &&
        item.generalComment === '' &&
        item.vocabularyAndGrammar === '' &&
        item.scoreFluencyInSpeaking === 0 &&
        item.scoreGeneralComment === 0 &&
        item.scorePronunciation === 0 &&
        item.scorePronunciation === 0) ||
        (item.fluencyInSpeaking === null &&
          item.pronunciation === null &&
          item.generalComment === null &&
          item.vocabularyAndGrammar === null &&
          item.scoreFluencyInSpeaking === null &&
          item.scoreGeneralComment === null &&
          item.scorePronunciation === null &&
          item.scorePronunciation === null)
        ? false
        : true
    },
    async getStudentRecordingByClassID() {
      await class_managerment.getStudentRecordingByClassID(this.classID).then(res => {
        let studentGroup = []
        res.forEach(item => {
          if (
            (item.fluencyInSpeaking === '' &&
              item.pronunciation === '' &&
              item.generalComment === '' &&
              item.vocabularyAndGrammar === '' &&
              item.scoreFluencyInSpeaking === 0 &&
              item.scoreGeneralComment === 0 &&
              item.scorePronunciation === 0 &&
              item.scorePronunciation === 0) ||
            (item.fluencyInSpeaking === null &&
              item.pronunciation === null &&
              item.generalComment === null &&
              item.vocabularyAndGrammar === null &&
              item.scoreFluencyInSpeaking === null &&
              item.scoreGeneralComment === null &&
              item.scorePronunciation === null &&
              item.scorePronunciation === null)
          ) {
            item.isCommentOrMark = false
          } else {
            item.isCommentOrMark = true
          }
          if (!studentGroup.find(student => item.studentID === student.studentID)) {
            studentGroup.push({
              studentName: item.studentName,
              studentID: item.studentID,
              studentCode: item.studentCode,
              student_userID: item.student_userID,
              classCode: item.classCode,
              dob: item.dob,
              startDate: item.startDate,
              qcName: item.qc,
              ecName: item.ec,
              listRecord: [item],
            })
          } else {
            studentGroup = studentGroup.map(student => {
              return student.studentID === item.studentID
                ? { ...student, listRecord: [...student.listRecord, item] }
                : student
            })
          }
        })
        this.recordData = this.students.reduce((arr, item) => {
          if (studentGroup.find(student => student.studentID === item.studentID)) {
            studentGroup.map(el => {
              if (el.studentID === item.studentID) {
                el.dob = item.dob
                el.startDate = item.startDate
                el.qcName = item.qc
                el.ecName = item.ec
                el.ecid = item.ec_userID
                el.qcid = item.qc_userID
                el.classID = item.classID
              }
            })
            arr.push(studentGroup.find(student => student.studentID === item.studentID))
          } else {
            arr.push({
              studentName: item.fullName,
              studentID: item.studentID,
              studentCode: item.studentCode,
              student_userID: item.userID,
              dob: item.dob,
              startDate: item.startDate,
              qcName: item.qc,
              ecName: item.ec,
              ecid: item.ec_userID,
              qcid: item.qc_userID,
              classID: item.classID,
              classCode: item.classCode,
              listRecord: [],
            })
          }
          return arr
        }, [])
      })
    },
    changefilAPI() {
      this.searchClass()
    },
    _dayjs: dayjs,
  },
  watch: {
    students: function () {
      // this.
      this.getStudentRecordingByClassID()
    },
  },
}
</script>
<style lang="scss" scoped>
.text-right {
  text-align: right;
}
.record-form {
  width: 100%;
}
#recording-management {
  .link {
    cursor: pointer;

    color: #427df2 !important;
  }
  .w-150 {
    width: 100px;
  }
  .record-header-item {
    width: 160px;
  }
  .link:hover {
    text-decoration: underline;
    font-weight: 700;
  }
}

.dig-video .v-dialog {
  box-shadow: none !important;
}
.h {
  background: rgba(0, 0, 0, 0.01);
  position: fixed;
  width: 100%;
  height: 100%;

  top: 0;
  z-index: 1000;
  left: 0;
}
.link-toNewTab {
  float: right;
}
.iconEdit {
  opacity: 0.6;
}
.record-table {
  border-top: 1px solid #dae3e8 !important;
}
.btn-comment-record {
  width: 120px;
  font-size: 10px;
}
.record-header th {
  color: #fff !important;
  background: #0c2e68 !important;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  text-align: center;
}
.record-header th:nth-child(1) {
  position: sticky;
  left: 0;
  z-index: 10;
}
.record-header th:nth-child(2) {
  position: sticky;
  left: 50px;
  z-index: 10;
}
.record-header th:nth-child(3) {
  position: sticky;
  left: 170px;
  z-index: 10;
}
.record-header-item p {
  font-weight: 500;
  width: 100px;
}
.record-body tr:nth-child(even) {
  background: #eaf1ff;
}
.header-comment {
  width: 100%;
}
.record-body tr td:nth-child(1) {
  position: sticky;
  left: 0;
}
.record-body tr td:nth-child(2) {
  position: sticky;
  // left: 100%;
  left: 50px;
}
.record-body tr td:nth-child(3) {
  position: sticky;
  left: 170px;
}
.record-body tr:nth-child(even) td:nth-child(3) {
  background: #eaf1ff !important;
}
.record-body tr:nth-child(odd) td:nth-child(3) {
  background: #fff !important;
}
.record-body tr:nth-child(even) td:nth-child(2) {
  background: #eaf1ff !important;
}
.record-body tr:nth-child(odd) td:nth-child(2) {
  background: #fff !important;
}
.record-body tr:nth-child(even) td:nth-child(1) {
  background: #eaf1ff !important;
}
.record-body tr:nth-child(odd) td:nth-child(1) {
  background: #fff !important;
}
.record-body tr:nth-child(even) {
  background: #eaf1ff !important;
}
.record-body tr:nth-child(odd) {
  background: #fff !important;
}
.fz-1 {
  font-size: 16px;
}
</style>
