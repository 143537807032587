<template>
  <div
    v-if="currentRole === 4 || currentRole === 5"
    id="recording-management"
    class="d-flex flex-column"
  >
    <v-row no-gutters>
      <v-col md="12">
        <div class="pa-10 pt-5">
          <div class="d-flex justify-space-between align-center">
            <h2 class="pt-1">Student Recording</h2>
            <v-radio-group v-model="selectedSection" row>
              <v-radio label="View by date" value="recordSectionByDate"></v-radio>
              <v-radio label="View by class" value="recordSectionByClass"></v-radio>
            </v-radio-group>
          </div>
          <v-divider></v-divider>
          <RecordByClass v-if="selectedSection==='recordSectionByClass'"/>
          <RecordByDate v-if="selectedSection==='recordSectionByDate'"/>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import RecordByClass from './RecordByClass.vue'
import RecordByDate from './RecordByDate.vue'
import { getObjectPermission } from '../../../common/index'
export default {
  components: { RecordByClass, RecordByDate },
  data() {
    return {
      currentRole: JSON.parse(localStorage.getItem('currentUser')).userInfo.accountTypeID,
      permision: getObjectPermission(401),
      selectedSection: 'recordSectionByDate',
    }
  },
  

  methods:{},
}
</script>
<style lang="scss" scoped></style>
